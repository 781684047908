var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"variant"},_vm._l((_vm.view.views),function(v,v_i){return _c('span',{key:v_i},[(v.type == 'variant')?_c('span',{class:_vm.hover_variants[v_i] || _vm.hover_sequence[v_i]
            ? 'variant-s-hover'
            : 'variant-s',on:{"mouseover":function($event){_vm.hover_variants[v_i] = true;
          _vm.hover_sequence[v_i] = true;},"mouseleave":function($event){_vm.hover_variants[v_i] = false;
          _vm.hover_sequence[v_i] = false;},"click":function($event){return _vm.scroll_to_variant(v_i)}}},[_vm._v(_vm._s(v.description))]):_vm._e(),(v.type == 'variant' && v_i < _vm.view.views.length - 2)?_c('span',[_vm._v(";")]):_vm._e()])}),0),_c('div',{staticClass:"wrapper",attrs:{"id":"parent-div"}},[(!_vm.view.inverted)?_c('v-icon',{staticClass:"mr-2",attrs:{"id":"sense-arrow"}},[_vm._v("mdi-arrow-right-bold")]):_vm._e(),(_vm.view.inverted)?_c('v-icon',{staticClass:"mr-2",attrs:{"id":"sense-arrow"}},[_vm._v("mdi-arrow-left-bold")]):_vm._e(),_vm._l((_vm.view.views),function(v,v_i){return _c('div',{key:'v' + v_i,staticClass:"seq"},[(v.type == 'outside' && v.sequence)?_c('div',_vm._l((v.sequence),function(s,s_i){return _c('span',{key:'s' + s_i,ref:_vm.get_html_id(_vm.get_position(v, s_i, 'sequence')),refInFor:true,attrs:{"id":_vm.get_html_id(_vm.get_position(v, s_i, 'sequence'))}},[_c('v-list-item-action',{staticClass:"ma-0 pa-0",staticStyle:{"min-width":"unset"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var onTooltip = ref.on;
return [_c('span',_vm._g({class:_vm.get_seq_class(v, s_i, 'sequence')},Object.assign({}, onMenu, onTooltip)),[_vm._v(_vm._s(s))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.get_position(v, s_i, "sequence"))+" ")])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"text-right"},[_c('v-btn',{attrs:{"small":"","text":"","color":"primary"}},[_vm._v(" Get HGVS location ")])],1)],1)],1)],1)],1)}),0):_vm._e(),(v.type == 'outside' && v.left)?_c('div',[_vm._l((v.left),function(s,s_i){return _c('span',{key:'l' + s_i,ref:_vm.get_html_id(_vm.get_position(v, s_i, 'sequence')),refInFor:true,attrs:{"id":_vm.get_html_id(_vm.get_position(v, s_i, 'sequence'))}},[_c('v-list-item-action',{staticClass:"ma-0 pa-0",staticStyle:{"min-width":"unset"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var onTooltip = ref.on;
return [_c('span',_vm._g({class:_vm.get_seq_class(v, s_i, 'left')},Object.assign({}, onMenu, onTooltip)),[_vm._v(_vm._s(s))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.get_position(v, s_i, "left")))])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"text-right"},[_c('v-btn',{attrs:{"small":"","text":"","color":"primary"}},[_vm._v(" Get HGVS location ")])],1)],1)],1)],1)],1)}),_c('div',{staticClass:"seq",attrs:{"id":_vm.get_html_id(_vm.get_position_other(v, null, 'other'))}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({ref:_vm.get_html_id(_vm.get_position_other(v, null, 'other')),refInFor:true,staticClass:"seq-elem"},'span',attrs,false),on),[_vm._v("...")])]}}],null,true)},[_c('span',[_vm._v("other "+_vm._s(_vm.get_position_other_tooltip(v, null, "other"))+" bases")])])],1),_vm._l((v.right),function(s,s_i){return _c('span',{key:'r' + s_i,attrs:{"id":_vm.get_html_id(_vm.get_position(v, s_i, 'right'))}},[_c('v-list-item-action',{staticClass:"ma-0 pa-0",staticStyle:{"min-width":"unset"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var onTooltip = ref.on;
return [_c('span',_vm._g({class:_vm.get_seq_class(v, s_i, 'right')},Object.assign({}, onMenu, onTooltip)),[_vm._v(_vm._s(s))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.get_position(v, s_i, "right")))])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"text-right"},[_c('v-btn',{attrs:{"small":"","text":"","color":"primary"}},[_vm._v(" Get HGVS location ")])],1)],1)],1)],1)],1)})],2):_vm._e(),(v.type == 'variant')?_c('div',{ref:_vm.d_id + '_variant_' + v_i,refInFor:true,staticClass:"seq",class:_vm.get_variant_seq_class(v_i),attrs:{"id":_vm.d_id + '_variant_' + v_i},on:{"mouseover":function($event){_vm.hover_variants[v_i] = true;
          _vm.hover_sequence[v_i] = true;},"mouseleave":function($event){_vm.hover_variants[v_i] = false;
          _vm.hover_sequence[v_i] = false;}}},[(
            (v.deleted || v.inserted) &&
            v.description &&
            !v.description.includes('=')
          )?_c('div',[(v.deleted && v.deleted.sequence)?_c('div',{staticClass:"seqdel"},_vm._l((v.deleted.sequence),function(s,s_i){return _c('span',{key:'ds' + s_i,ref:_vm.get_html_id(_vm.get_position(v, s_i, 'sequence')),refInFor:true,staticClass:"seq-elem",attrs:{"id":_vm.get_html_id(_vm.get_position(v, s_i, 'sequence'))}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',[_vm._v(_vm._s(s))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.get_position(v, s_i, "sequence")))])])],1)}),0):_vm._e(),(v.deleted && v.deleted.left)?_c('div',{staticClass:"seqdel"},_vm._l((v.deleted.left),function(s,s_i){return _c('span',{key:s_i,ref:_vm.get_html_id(_vm.get_position(v, s_i, 'left')),refInFor:true,staticClass:"seq-elem",attrs:{"id":_vm.get_html_id(_vm.get_position(v, s_i, 'left'))}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',[_vm._v(_vm._s(s))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.get_position(v, s_i, "left")))])])],1)}),0):_vm._e(),(v.deleted && v.deleted.right)?_c('div',{staticClass:"seqdel",attrs:{"id":_vm.get_html_id(_vm.get_position_other(v, null, 'other-deleted'))}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({ref:'seq-' + _vm.get_position_other(v, null, 'other-deleted'),refInFor:true,staticClass:"seq-elem"},'span',attrs,false),on),[_c('span',[_vm._v("...")])])]}}],null,true)},[_c('span',[_vm._v("other "+_vm._s(_vm.get_position_other_tooltip(v, null, "other-deleted"))+" bases")])])],1):_vm._e(),(v.deleted && v.deleted.right)?_c('div',{staticClass:"seqdel"},_vm._l((v.deleted.right),function(s,s_i){return _c('span',{key:s_i,ref:_vm.get_html_id(_vm.get_position(v, s_i, 'right-deleted')),refInFor:true,staticClass:"seq-elem",attrs:{"id":_vm.get_html_id(_vm.get_position(v, s_i, 'right-deleted'))}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',[_vm._v(_vm._s(s))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.get_position(v, s_i, "right-deleted")))])])],1)}),0):_vm._e(),(!v.deleted)?_c('div',[_c('span',{staticClass:"seq"},[_vm._v("-")])]):_vm._e(),_c('v-divider'),(v.inserted && v.inserted.sequence)?_c('div',{staticClass:"seqins"},_vm._l((v.inserted.sequence),function(s,s_i){return _c('span',{key:s_i,staticClass:"seq-elem"},[_vm._v(_vm._s(s))])}),0):_vm._e(),(v.inserted && v.inserted.left)?_c('div',{staticClass:"seqins"},_vm._l((v.inserted.left),function(s,s_i){return _c('span',{key:s_i,staticClass:"seq-elem"},[_vm._v(_vm._s(s))])}),0):_vm._e(),(v.inserted && v.inserted.right)?_c('div',{staticClass:"seqins"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"seq-elem"},'span',attrs,false),on),[_c('span',[_vm._v("...")])])]}}],null,true)},[_c('span',[_vm._v("other "+_vm._s(_vm.get_position_other_tooltip(v, null, "other-inserted"))+" bases")])])],1):_vm._e(),(v.inserted && v.inserted.right)?_c('div',{staticClass:"seqins"},_vm._l((v.inserted.right),function(s,s_i){return _c('span',{key:s_i,staticClass:"seq-elem"},[_vm._v(_vm._s(s))])}),0):_vm._e(),(!v.inserted)?_c('div',[_c('span',{staticClass:"seq"},[_vm._v("-")])]):_vm._e()],1):_c('div',[(v.deleted && v.deleted.sequence)?_c('div',{staticClass:"seqdelequal"},_vm._l((v.deleted.sequence),function(s,s_i){return _c('span',{key:'ds' + s_i,staticClass:"seq-elem",attrs:{"id":_vm.get_html_id(_vm.get_position(v, s_i, 'sequence'))}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',[_vm._v(_vm._s(s))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.get_position(v, s_i, "sequence")))])])],1)}),0):_vm._e(),(v.deleted && v.deleted.left)?_c('div',{staticClass:"seqdelequal"},_vm._l((v.deleted.left),function(s,s_i){return _c('span',{key:s_i,staticClass:"seq-elem",attrs:{"id":_vm.get_html_id(_vm.get_position(v, s_i, 'left'))}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',[_vm._v(_vm._s(s))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.get_position(v, s_i, "left")))])])],1)}),0):_vm._e(),(v.deleted && v.deleted.right)?_c('div',{staticClass:"seqdelequal",attrs:{"id":_vm.get_html_id(_vm.get_position_other(v, null, 'other-deleted'))}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"seq-elem"},'span',attrs,false),on),[_c('span',[_vm._v("...")])])]}}],null,true)},[_c('span',[_vm._v("other "+_vm._s(_vm.get_position_other_tooltip(v, null, "other-deleted"))+" bases")])])],1):_vm._e(),(v.deleted && v.deleted.right)?_c('div',{staticClass:"seqdelequal"},_vm._l((v.deleted.right),function(s,s_i){return _c('span',{key:s_i,staticClass:"seq-elem",attrs:{"id":_vm.get_html_id(_vm.get_position(v, s_i, 'right-deleted'))}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',[_vm._v(_vm._s(s))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.get_position(v, s_i, "right-deleted")))])])],1)}),0):_vm._e()])]):_vm._e()])}),(!_vm.view.inverted)?_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-arrow-right-bold")]):_vm._e(),(_vm.view.inverted)?_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-arrow-left-bold")]):_vm._e(),(_vm.features_boundaries)?_c('div',{staticClass:"mt-3 mb-3"},_vm._l((_vm.features_boundaries),function(f,f_i){return _c('div',{key:f_i,staticStyle:{"display":"inline"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(f.style)},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.get_feature_tooltip(f)))])])],1)}),0):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }